import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { deletePassword } from '../../components/redux/PasswordReducer';

const Password = () => {
  let sn = 0;
  const passwords = useSelector((state) => state.passwords);
  const dispatch = useDispatch();

  // Modal
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  // Toast
  const [showToast, setShowToast] = useState(false);
  const handleShowToast = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 2000); // Hide after 3 seconds
  };
  const handleCloseToast = () => setShowToast(false);

  const handleDelete = (id) => {
    dispatch(deletePassword({id:id}));
    handleShowToast();
    handleClose();
  }

  return (
    <div className='container mt-4'>
      <h4>Password Management</h4>
      <Link to='/create-password' className='btn btn-success mt-4 mb-4'>Add New Password +</Link>
      {/* Toast */}
      <div className="toast-container position-fixed top-0 p-3" style={{ zIndex: 11 }}>
        <div className={`toast ${showToast ? 'show' : 'hide'} bg-success`} role="alert" aria-live="assertive" aria-atomic="true">
          <div className="toast-header">
            <strong className="me-auto">Notification</strong>
            <button type="button" className="btn-close" aria-label="Close" onClick={handleCloseToast}></button>
          </div>
          <div className="toast-body text-light">
            Project deleted successfully!
          </div>
        </div>
      </div>
      <div className='table-responsive'>
        <table className='table'>
            <thead>
            <tr>
                <th>S/N</th>
                <th>Project</th>
                <th>url</th>
                <th>username</th>
                <th>Password</th>
                <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {passwords.map((password, index)=> (
                <tr key={index}>
                    <td>{sn+=1}</td>
                    <td>{password.project}</td>
                    <td>{password.url}</td>
                    <td>{password.username}</td>
                    <td>{password.password}</td>
                    <td>
                      <Link to={`/update-password/${password.id}`} className='btn text-primary'>Edit</Link>
                      <button onClick={handleShow} className='btn text-danger'>Delete</button>
                    </td>
                    {/* Modal */}
                      {show && (
                        <div className="modal fade show d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">{password.project}</h5>
                                <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
                              </div>
                              <div className="modal-body">
                                <p>Are You Sure You want to Delete This Project?</p>
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-danger" onClick={()=>handleDelete(password.id)}>
                                  Yes
                                </button>
                                <button type="button" className="btn btn-info" onClick={handleClose}>
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                </tr>
            ))}
            </tbody>
        </table>
      </div>
    </div>
  )
}

export default Password
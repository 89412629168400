import { createSlice } from '@reduxjs/toolkit';
import { passwordList } from '../Data';

const PasswordSlice = createSlice({
    name: 'passwords',
    initialState: passwordList,
    reducers: {
        addPassword : (state, action) => {
            state.push(action.payload);
        },
        updatePassword: (state, action) => {
            const {id, username, password, url } = action.payload;
            const updated_password = state.find(password => password.id === id);
            if(updated_password){
                updated_password.username = username;
                updated_password.password = password;
                updated_password.url = url;
            }
        },
        deletePassword: (state, action) => {
            const {id} = action.payload;
            const existing_password = state.find(password => password.id === id);
            if(existing_password){
                return state.filter(f => f.id !== id);
            }
        }
    }
})

export const {addPassword, updatePassword, deletePassword} = PasswordSlice.actions;
export default PasswordSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";
import { projectList} from "../Data";

const projectSlice = createSlice({
    name:'projects',
    initialState: projectList,
    reducers:  {
       addProject: (state, action) => {
        state.push(action.payload);
       }
    }
})

export const { addProject } = projectSlice.actions;
export default projectSlice.reducer;
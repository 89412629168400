import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { addProject } from '../../components/redux/ProjectReducer';



const Create = () => {
  const [project, setProject] = useState('');
  const [url, setUrl] = useState('');
  const [technology, setTechnology] = useState('');
  const [date, setDate] = useState('');

  const dispatch = useDispatch();
  const projects = useSelector((state)=> state.projects);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addProject({id:projects[projects.length -1 ].id + 1, project, url, technology, date}));
    navigate('/');
  }

  return (
    <div className='container mt-4'>
      <div className='d-flex justify-content-between align-items-center'><h5>Add New Project</h5><Link to='/' className='btn text-success'>Home</Link></div>
      <form onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col-lg-6 mt-4'>
            <input 
              type='text' 
              className='form-control' 
              placeholder='Enter Project' 
              name='project'
              value={project}
              onChange = { e => setProject(e.target.value)}
            />
          </div>
          <div className='col-lg-6 mt-4'>
            <input 
              type='text' 
              className='form-control' 
              placeholder='Enter url' 
              name='url'
              value={url}
              onChange = { e => setUrl(e.target.value)}
            />
          </div>
          <div className='col-lg-6 mt-4'>
            <input 
              type='text' 
              className='form-control' 
              placeholder='Enter Technology' 
              name='technology'
              value={technology}
              onChange = { e => setTechnology(e.target.value)}
            />
          </div>
          <div className='col-lg-6 mt-4'>
            <input 
              type='date' 
              className='form-control' 
              placeholder='Enter Start Date' 
              name='date'
              value={date}
              onChange = { e => setDate(e.target.value)}
            />
          </div>
          <div>
            <button className='btn btn-success mt-4 form-control'>ADD PROJECT</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Create
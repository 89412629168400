export const userList = [
    {
        id:1,
        name: 'Sam1',
        email: 'sam1@yahoo.com'
    },
    {
        id:2,
        name: 'Sam2',
        email: 'sam2@yahoo.com'
    },
    {
        id:3,
        name: 'Sam3',
        email: 'sam@yahoo.com'
    },
]

export const projectList = [
    {
        id:1,
        project: 'project1',
        url: 'sam1@yahoo.com',
        technology: 'Wordpress',
        date:'',
        status: 'active',
    },
    {
        id:2,
        project: 'project2',
        url: 'sam2@yahoo.com',
        technology: 'Wordpress',
        date:'',
        status: 'inactive',
    },
    {
        id:3,
        project: 'project3',
        url: 'sam3@yahoo.com',
        technology: 'Wordpress',
        date:'',
        status: 'active',
    },   
]

export const passwordList = [
    {
        id:1,
        project: 'project1',
        url: 'sam1@yahoo.com',
        password: 'gvjvhvvhvh',
        username: 'admin'
    },
    {
        id:2,
        project: 'project2',
        url: 'sam2@yahoo.com',
        password: 'bsdkbobsoubdouds',
        username: 'admin'
    },
    {
        id:3,
        project: 'project3',
        url: 'sam3@yahoo.com',
        password: 'vsdhvhkdvskvdksv',
        username: 'admin'
    } 
]
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updatePassword } from '../../components/redux/PasswordReducer';

const Update = () => {
  const {id} = useParams();
  const passwords = useSelector((state) => state.passwords);
  const selected_p = passwords.filter(ip_d => (ip_d.id == id));
  const {password, username, url} = selected_p[0];

  const [u_username, setUsername] = useState(username);
  const [u_password, setPassword] = useState(password);
  const [u_url, setUrl] = useState(url);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUpdate = (e) => {
    e.preventDefault();
    dispatch(updatePassword({id:id, username:u_username, password:u_password, url:u_url}));
    navigate('/passwords');
  }
  return (
    <div className='container mt-4'>
      <h5>Update Password</h5>
      <form onSubmit={handleUpdate}>
        <div className='row'>
        <div className='col-lg-6 mt-4'>
            <label htmlFor='url'>Enter Url</label>
            <input type='text' className='form-control' name='url' value={u_url} onChange={e => setUrl(e.target.value)} />
          </div>
          <div className='col-lg-6 mt-4'>
            <label htmlFor='username'>Enter Username</label>
            <input type='text' className='form-control' name='username' value={u_username} onChange={e => setUsername(e.target.value)}/>
          </div>
          <div className='col-lg-6 mt-4'>
            <label htmlFor='password'>Enter Password</label>
            <input type='text' className='form-control' name='password' value={u_password} onChange={e => setPassword(e.target.value)} />
          </div>
          <div>
            <button className='btn btn-success mt-4 form-control' to="#">Update</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Update
import { createSlice } from "@reduxjs/toolkit";
import { userList } from "../Data";

const userSlice = createSlice({
    name:'users',
    initialState: userList,
    reducers:  {
        addUser: (state, action) => {
            state.push(action.payload);
        },
        updateUser: (state, action) => {
            const {id, name, email } = action.payload;
            const updated_user = state.find(user => user.id === id);
            if(updated_user){
                updated_user.name = name;
                updated_user.email = email;
            }
        },
        deleteUser: (state, action) => {
            const {id} = action.payload;
            const existing_user = state.find(user => user.id === id);
            if(existing_user){
                return state.filter(f => f.id !== id);
            }
        }
    }
})

export const {addUser, updateUser, deleteUser} = userSlice.actions;
export default userSlice.reducer;
import React from 'react'
import Home from './pages/projects'
import CreateProject from './pages/projects/Create'
import CreatePassword from './pages/passwords/Create'
import UpdatePassword from './pages/passwords/Update'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Header from './components/header/Header'
import Passwords from './pages/passwords'

const App = () => {
  return (
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/passwords' element={<Passwords/>}></Route>
        <Route path='/create-project' element={<CreateProject/>}></Route>
        <Route path='/create-password' element={<CreatePassword/>}></Route>
        {/* <Route path='/edit/:id' element={<Update/>}></Route> */}
        <Route path='/update-password/:id' element={<UpdatePassword/>}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
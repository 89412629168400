import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { addPassword } from '../../components/redux/PasswordReducer';
import { useDispatch, useSelector } from 'react-redux';


const Create = () => {

  const [project, setProject] = useState('');
  const [url, setUrl] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const passwords = useSelector((state) => state.passwords)

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addPassword({id:passwords[passwords.length -1 ].id + 1, username, url, password, project}));
    navigate('/passwords');
  }

  return (
    <div className='container mt-4'>
      <div className='d-flex justify-content-between align-items-center'><h5>Add New Password</h5><Link to='/' className='btn text-success'>Home</Link></div>
      <form onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col-lg-6 mt-4'>
            <input 
              type='text' 
              className='form-control' 
              placeholder='Enter Project' 
              name='project'
              value={project}
              onChange = { e => setProject(e.target.value)}
            />
          </div>
          <div className='col-lg-6 mt-4'>
            <input 
              type='text' 
              className='form-control' 
              placeholder='Enter url' 
              name='url'
              value={url}
              onChange = { e => setUrl(e.target.value)}
            />
          </div>
          <div className='col-lg-6 mt-4'>
            <input 
              type='text' 
              className='form-control' 
              placeholder='Enter Username' 
              name='username'
              value={username}
              onChange = { e => setUsername(e.target.value)}
            />
          </div>
          <div className='col-lg-6 mt-4'>
            <input 
              type='text' 
              className='form-control' 
              placeholder='Enter Password' 
              name='password'
              value={password}
              onChange = { e => setPassword(e.target.value)}
            />
          </div>
          <div>
            <button className='btn btn-success mt-4 form-control'>ADD PASSWORD</button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Create
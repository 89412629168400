import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';


const Home = () => {
  const projects = useSelector((state) => state.projects);
  let sn = 0;
  
  return (
    <div className='container mt-4'>
      <h4>Project Management System</h4>
      <Link to='/create-project' className='btn btn-success mt-4 mb-4'>Add Project +</Link>
      <div className='table-responsive'>
        <table className='table'>
            <thead>
            <tr>
                <th>S/N</th>
                <th>Project</th>
                <th>url</th>
                <th>Technology</th>
                <th>Date</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
            {projects.map((project, index)=> (
                <tr key={index}>
                    <td>{sn+=1}</td>
                    <td>{project.project}</td>
                    <td>{project.url}</td>
                    <td>{project.technology}</td>
                    <td>{project.date}</td>
                    <td className={`${project.status === 'active' ? 'text-success' : 'text-danger'}`}>{project.status}</td>
                </tr>
            ))}
            </tbody>
        </table>
      </div>
    </div>
  )
}

export default Home